<template>
  <v-app id="rc-kiosk">
    <router-view />

    <span class="rc-wrapper-refresh">
      <v-btn
        text
        icon
        color="green"
        v-show="showRefreshBtn"
        @click="reloadPage()"
      >
        <v-icon>cached</v-icon>
      </v-btn>
    </span>
  </v-app>
</template>

<script>
export default {
  name: `LayoutKioskRogers`,
  data() {
    return {
      drawer: {
        open: null,
        clipped: true,
        fixed: true,
      },
      toolbar: {
        clippedLeft: true,
      },
      showRefreshBtn: false,
    }
  },
  created() {
    let app = this
    setTimeout(() => {
      this.showRefreshBtn = true
    }, 10000)

    setInterval(function () {
      var currDate = new Date()
      var hour = currDate.getHours()
      var minutes = currDate.getMinutes()
      if (hour == 0 && minutes == 0) {
        app.reloadPage()
      }
    }, 1000 * 60)
  },
  methods: {
    makeDrawerPermanent() {
      this.drawer.open = !this.drawer.open
    },
    reloadPage() {
      window.location.reload(false)
    },
  },
}
</script>

<style lang="sass">
.rc-wrapper-refresh
  position: absolute
  bottom: 2px
  left: 3px
</style>
